<template>
  <div>
    <div class="full-width">
      <div
        v-click-out="() => (isOpen = false)"
        class="p-dropdown p-dropdown--large alarm-filter_wrapper"
        :focused="isOpen"
        @click="toggleMenu"
      >
        <div class="p-dropdown__cover">
          <div class="p-dropdown__inner">
            <input id="id" :value="asset.name" class="p-dropdown__field" name="id" readonly />
            <label for="id" class="p-dropdown__label text-nowrap" v-text="$t('alarm.asset')" />
          </div>
        </div>

        <div v-if="isOpen" class="alarm-filter_dropdown" @click.stop>
          <currency-search-list
            :list="filteredList"
            :selected-market="selectedMarket"
            @market-selected="onMarketSelected"
          />
        </div>

        <p-icon
          icon="caret-down"
          size="md"
          color="fg-tertiary"
          class="p-dropdown__icon p-dropdown__icon--rotate"
        />
      </div>
    </div>
    <currency-list v-if="filteredList.length > 0" class="mt-24" :list="filteredList" />
    <empty-state
      v-else
      class="mt-48"
      :title="
        $t('alarm.currencyAlarmNoResult', {
          market: selectedMarket?.pairs?.market.toUpperCase(),
          payment: selectedMarket?.pairs?.payment.toUpperCase(),
        })
      "
      icon="alarm-add"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
})

const isOpen = ref<boolean>(false)
const asset = ref<{ name: string; value: string }>({ name: $t('general.all'), value: 'all' })
const selectedMarket = ref<any | null>(null)

const toggleMenu = () => {
  isOpen.value = !isOpen.value
}

const filteredList = computed(() => {
  if (!selectedMarket.value || selectedMarket.value.id === 'all') {
    return props.list
  }

  return props.list.filter((item) => item.market === selectedMarket.value.id)
})

const onMarketSelected = (market: any) => {
  isOpen.value = false
  selectedMarket.value = market

  asset.value = {
    name: $t(market?.name)?.toUpperCase() ?? $t('general.all'),
    value: market?.id ?? 'all',
  }
}
</script>
