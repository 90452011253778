<template>
  <div class="place-items-center flex-column full-width">
    <div v-for="(group, groupIndex) in structuredAlarms" :key="groupIndex" class="pb-16 full-width">
      <div class="place-items-start gap-8 my-8 py-4">
        <nuxt-img :src="icon(group.market)" width="24" height="24" />
        <div class="t-fg-primary f-body-16-medium" v-text="group.market" />
      </div>

      <div v-for="(alarm, index) in group.alarms" :key="index">
        <div class="alarm-list-item">
          <div class="place-items-center gap-8">
            <p-icon
              :icon="alarm.direction"
              size="md"
              :color="alarm.direction === 'up' ? 'fg-accent' : 'fg-danger'"
            />
            <div class="align-items-start place-items-start flex-column gap-4">
              <div
                class="t-fg-primary f-utility-14"
                v-html="
                  $t('alarm.priceChange', {
                    sign: `${alarm.direction !== 'down' ? '+' : '-'}%`,
                    amount: alarm.amount,
                    accentClass: `${alarm.direction !== 'down' ? 't-fg-accent' : 't-fg-danger'} f-utility-14-medium`,
                  })
                "
              />
              <div
                class="t-fg-tertiary f-utility-12"
                v-text="
                  $t('alarm.targetPrice', {
                    triggerPrice: alarm.triggerPrice,
                    currency: selectedMarket(alarm.market)?.pairs?.payment.toUpperCase(),
                  })
                "
              />
            </div>
          </div>
        </div>
        <p-divider v-if="group.alarms.length - 1 > index" class="my-2 ml-32" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
})

const mainStore = useMainStore()

const restructureAlarmsByMarket = (alarms: any[]) => {
  const grouped = alarms.reduce(
    (acc, alarm) => {
      const market = alarm.market.replace('_', '/').toUpperCase()
      if (!acc[market]) {
        acc[market] = []
      }
      acc[market].push(alarm)
      return acc
    },
    {} as Record<string, any[]>,
  )

  return Object.entries(grouped).map(([market, alarms]) => ({
    market,
    alarms,
  }))
}

const structuredAlarms = computed(() => {
  return restructureAlarmsByMarket(props.list)
})

const selectedMarket = (market: string) => {
  if (typeof market === 'string') {
    return mainStore.getMarkets?.[market] || {}
  }
  return {}
}

const selectedCurrency = (market: string) => {
  const marketKey = selectedMarket(market)?.pairs?.market
  return marketKey ? mainStore.getCurrencies?.[marketKey] || {} : {}
}

const icon = (market: string) => {
  const marketName = market.replace('/', '_').toLowerCase()
  return getAsset({ asset: selectedCurrency(marketName)?.icon, type: 'coin' })
}
</script>
