<template>
  <div class="my-8">
    <div class="place-items-start mb-16 gap-8">
      <div>
        <div class="f-body-16-medium t-fg-primary" v-text="$t('alarm.fastAlarms')" />
        <div class="p-info-card__icon p-info-card__icon__success" />
      </div>
      <p-tooltip direction="right-top">
        <p-button
          class="alarm-list__button px-4"
          size="sm"
          status="success"
          button-type="ghost"
          block="true"
        >
          <p-icon icon="info-regular" size="md" color="fg-tertiary" />
        </p-button>
        <template #content>
          <div
            v-for="(item, index) in alarmItems"
            :key="index"
            class="fast-alarms__tooltip"
            :style="{ marginBottom: index !== alarmItems.length - 1 ? '16px' : '0' }"
          >
            <span v-text="$t(item.title)" /><br v-if="index === 0" />
            <span class="fast-alarms__tooltip--desc" v-text="$t(item.description)" />
          </div>
        </template>
      </p-tooltip>
    </div>

    <div>
      <p-switch
        v-model="walletChange"
        name="walletChange"
        class="alarm-switch f-utility-14 t-fg-primary place-items-center py-12"
      >
        <template #prepend>
          <div class="place-items-between gap-12 pr-12">
            <span v-text="$t('alarm.fastAlarmsInfo.1.title')" />
            <span v-text="formatPercent({ num: 10, precision: 0 })" />
          </div>
        </template>
      </p-switch>

      <p-divider />

      <p-switch
        v-model="riseFall"
        name="riseFall"
        class="alarm-switch f-utility-14 t-fg-primary place-items-center py-12"
      >
        <template #prepend>
          <div class="place-items-between gap-12 pr-12">
            <span v-text="$t('alarm.fastAlarmsInfo.2.title')" />
            <span v-text="formatPercent({ num: 10, precision: 0 })" />
          </div>
        </template>
      </p-switch>

      <p-divider />

      <p-switch
        v-model="fanMarketChange"
        name="fanMarketChange"
        class="alarm-switch f-utility-14 t-fg-primary place-items-center py-12"
      >
        <template #prepend>
          <div class="place-items-between gap-12 pr-12">
            <span v-text="$t('alarm.fastAlarmsInfo.3.title')" />
            <span v-text="formatPercent({ num: 10, precision: 0 })" />
          </div>
        </template>
      </p-switch>

      <p-divider />

      <p-switch
        v-model="boxMarketChange"
        name="boxMarketChange"
        class="alarm-switch f-utility-14 t-fg-primary place-items-center py-12"
      >
        <template #prepend>
          <div class="place-items-between gap-12 pr-12">
            <span v-text="$t('alarm.fastAlarmsInfo.4.title')" />
            <span v-text="formatPercent({ num: 10, precision: 0 })" />
          </div>
        </template>
      </p-switch>
    </div>
  </div>
</template>

<script setup lang="ts">
const walletChange = ref<boolean>(false)
const riseFall = ref<boolean>(false)
const fanMarketChange = ref<boolean>(false)
const boxMarketChange = ref<boolean>(false)

const alarmItems = computed(() => {
  return [1, 2, 3, 4].map((i) => ({
    title: `alarm.fastAlarmsInfo.${i}.title`,
    description: `alarm.fastAlarmsInfo.${i}.description`,
  }))
})
</script>
