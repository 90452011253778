<template>
  <p-drawer :steps="step" @on-close="smartRouter.exit()">
    <template #step1>
      <alarm-market-picker :list="alarms" />
    </template>
    <template #step1-actions>
      <p-icon
        icon="history"
        color="fg-secondary"
        class="mr-16 place-items-center px-4 cursor-pointer"
        @click="showIsAlarmHistory"
      />
    </template>

    <template #step2>
      <div class="t-fg-tertiary f-body-16-medium mb-16" v-text="$t('alarm.newCreateAlarmInfo')" />
      <alarm-search-list :list="alarms" />
    </template>

    <template #step3>
      <alarm-setter :is-show-overlay="false" />
    </template>

    <template #step4>
      <alarm-history :list="alarmHistoryList" />
      <div v-if="alarmHistoryList.length > 0"></div>
      <div v-else>
        <empty-state class="mt-48" :title="$t('alarm.emptyAlarmInfo')" icon="alarm-add" />
      </div>
    </template>
    <template #step4-actions>
      <p-tooltip direction="bottom-right">
        <p-icon
          icon="info-regular"
          color="fg-secondary"
          class="mr-16 place-items-center px-4 cursor-pointer"
        />
        <template #content>
          <div
            v-for="(item, index) in alarmHistoryInfo"
            :key="index"
            class="alarm-history__tooltip"
            :style="{ marginBottom: index !== alarmHistoryInfo.length - 1 ? '16px' : '0' }"
          >
            <span v-text="$t(item)" /><br v-if="index === 0" />
          </div>
        </template>
      </p-tooltip>
    </template>
  </p-drawer>
</template>

<script lang="ts" setup>
import type { MarketConfig, TickerList } from '~/models/main'

const router = useRouter()
const route = useRoute()
const mainStore = useMainStore()
const currentMarket = ref<MarketConfig | null>(null)

const alarms = [
  {
    alarmType: 'constant',
    triggerPrice: '1,812,522',
    direction: 'down',
    amount: '10',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '2,300.00.00',
    direction: 'up',
    amount: '83',
    market: 'ftm_tl',
  },
  {
    alarmType: 'constant',
    triggerPrice: '1,812,522',
    direction: 'down',
    amount: '10',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'ftm_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '2,300.00.00',
    direction: 'up',
    amount: '83',
    market: 'btc_tl',
  },
  {
    alarmType: 'constant',
    triggerPrice: '1,812,522',
    direction: 'down',
    amount: '10',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'eth_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '2,300.00.00',
    direction: 'up',
    amount: '83',
    market: 'eth_tl',
  },
]

const alarmHistoryList = [
  {
    alarmType: 'constant',
    triggerPrice: '1,812,522',
    direction: 'down',
    amount: '10',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '2,300.00.00',
    direction: 'up',
    amount: '83',
    market: 'ftm_tl',
  },
  {
    alarmType: 'constant',
    triggerPrice: '1,812,522',
    direction: 'down',
    amount: '10',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'ftm_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '2,300.00.00',
    direction: 'up',
    amount: '83',
    market: 'btc_tl',
  },
  {
    alarmType: 'constant',
    triggerPrice: '1,812,522',
    direction: 'down',
    amount: '10',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'eth_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '2,300.00.00',
    direction: 'up',
    amount: '83',
    market: 'eth_tl',
  },
]

const markets = computed((): MarketConfig => {
  return mainStore.getAllMarkets
})

const marketList = computed(() => {
  return mainStore.getMarketList
})

const tickers = computed((): TickerList => {
  return mainStore.getTickers
})

const lastPrice = computed(() => {
  const marketId = currentMarket.value?.id
  if (!marketId) return null

  return formatNumber({
    num: tickers.value[marketId]?.last,
    precision: (markets.value as Record<string, MarketConfig>)[marketId]?.precisions?.price,
  })
})

const paymentCurrency = computed(() => {
  const marketId = currentMarket.value?.id
  if (!marketId) return null

  return (
    (markets.value as Record<string, MarketConfig>)[marketId]?.pairs?.payment?.toUpperCase() ?? null
  )
})

const marketTitle = computed<string>(() => {
  return currentMarket?.value?.id?.replace('_', '/')?.toUpperCase?.() || ''
})

const icon = computed(() => {
  return getAsset({ asset: currentMarket?.value?.icon ?? '', type: 'coin' })
})

const showIsAlarmHistory = () => {
  router.push({ query: { ...route.query, step: 'alarmHistory' } })
}

const step = computed(() => {
  return {
    step1: {
      title: $t('alarm.title'),
      url: 'alarms',
      back: false,
    },
    step2: {
      title: $t('alarm.newCreateAlarm'),
      url: 'list',
      back: true,
      close: false,
      onBack: () => {
        smartRouter.back()
      },
    },
    step3: {
      title: marketTitle.value,
      url: 'setAlarm',
      back: true,
      close: false,
      description: `${lastPrice.value} ${paymentCurrency.value}`,
      headerTitleSlot: {
        icon: icon.value,
      },
      onBack: () => {
        smartRouter.back()
      },
    },
    step4: {
      title: $t('alarm.alarmHistory'),
      url: 'alarmHistory',
      back: true,
      close: false,
      onBack: () => {
        smartRouter.back()
      },
    },
  }
})
const alarmHistoryInfo = computed(() => {
  return [1, 2].map((i) => `alarm.alarmHistoryInfo.${i}`)
})

watch(
  () => router.currentRoute.value.query.market,
  (market) => {
    currentMarket.value = market ? marketList.value[market as string] || null : null
  },
  { immediate: true },
)
</script>
