<template>
  <div>
    <fast-alarms />
    <div class="mt-24">
      <div class="place-items-between mb-8">
        <div>
          <div class="f-body-16-medium t-fg-primary" v-text="$t('alarm.alarmList')" />
          <div class="p-info-card__icon p-info-card__icon__success" />
        </div>
        <p-button
          v-if="list.length > 0"
          class="alarm-list__button px-4"
          size="sm"
          status="success"
          button-type="ghost"
          block="true"
          @click="addNewAlarm"
        >
          <p-icon icon="plus-solid" size="md" />
        </p-button>
      </div>
      <alarm-list v-if="list.length > 0" :is-show-overlay="false" />

      <div v-else class="place-items-center mt-16">
        <p-button size="sm" status="success" button-type="ghost" block="true" @click="addNewAlarm">
          <span class="t-fg-accent t-utility-14-medium" v-text="$t('alarm.addAlarm')" />
          <p-icon icon="plus-solid" size="md" />
        </p-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  list: {
    type: Array,
    default: () => [],
  },
})

const router = useRouter()
const route = useRoute()

const addNewAlarm = () => {
  router.push({
    query: { ...route.query, step: 'list', market: route.query.market },
  })
}
</script>
