<template>
  <div>
    <p-search-input
      v-model="searchQuery"
      class="pt-8 pb-16"
      size="md"
      :placeholder="$t('general.search.asset')"
    />
    <transition name="fade-in">
      <div v-if="historySearches.length > 0" class="py-8">
        <div class="place-items-between mb-8">
          <div>
            <div class="f-body-16-medium t-fg-primary" v-text="$t('general.historySearches')" />
            <div class="p-info-card__icon p-info-card__icon__success" />
          </div>
          <p-button
            class="alarm-list__button px-4"
            size="sm"
            button-type="ghost"
            block="true"
            @click="toggleOrClearHistory"
          >
            <p-icon
              v-if="!showClearConfirmation"
              icon="trash-delete"
              size="md"
              color="fg-tertiary"
            />
            <div v-else class="t-fg-tertiary" v-text="$t('general.clear')" />
          </p-button>
        </div>
        <div class="alarm-history-searches">
          <div v-for="(market, index) in historySearches" :key="index">
            <p-chip
              class="alarm-history-searches__item"
              :label="market?.name?.toUpperCase()"
              @click="selectMarketToAlarm(market)"
            />
          </div>
        </div>
      </div>
    </transition>

    <div class="py-8">
      <div>
        <div class="f-body-16-medium t-fg-primary" v-text="$t('general.highlights')" />
        <div class="p-info-card__icon p-info-card__icon__success" />
      </div>
      <div class="alarm-highlights place-items-between pt-12 pb-8">
        <div v-for="(market, index) in highlights" :key="index" class="place-items-center gap-24">
          <div
            class="place-items-center gap-8 p-6 cursor-pointer"
            @click="selectMarketToAlarm(market)"
          >
            <span class="f-utility-14-medium t-fg-primary" v-text="market?.name?.toUpperCase()" />
            <nuxt-img
              :src="getAsset({ asset: market?.icon ?? '', type: 'coin' })"
              width="16"
              height="16"
            />
          </div>
          <p-divider v-if="index < highlights.length - 1" :vertical="true" style="height: 32px" />
        </div>
      </div>
    </div>

    <div class="mt-16 cursor-pointer">
      <div v-for="(market, index) in marketList" :key="index">
        <div class="p-menu__item" @click="selectMarketToAlarm(market)">
          <div class="p-menu__item__inner">
            <div>
              <div class="p-menu__item--title">
                <nuxt-img
                  :src="getAsset({ asset: market?.icon ?? '', type: 'coin' })"
                  width="20"
                  height="20"
                />

                <div>
                  <h4 v-text="market?.fullName" />
                  <div class="p-menu__item--desc" v-text="market?.name?.toUpperCase()" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p-divider v-if="marketList.length - 1 !== index" class="my-2 full-width" />
      </div>
    </div>

    <p-modal
      v-if="exceedCount"
      :modal-visibility="exceedCount"
      :width="$device.isDesktop ? '384px' : '343px'"
      @on-close="exceedCount = false"
    >
      <div class="place-items-center flex-column gap-16 full-width text-center">
        <p-icon icon="alert-regular" color="fg-danger" size="xl" />
        <div
          class="t-fg-primary f-title-20-bold"
          v-text="
            $t('alarm.maxLengthInfo', {
              currency: currentCurrency?.toUpperCase(),
            })
          "
        />

        <p class="t-fg-secondary f-body-14 mb-8" v-text="$t('alarm.maxLengthError')" />

        <p-button button-type="ghost" size="lg" class="full-width" @click="exceedCount = false">
          {{ $t('general.ok') }}
        </p-button>
      </div>
    </p-modal>

    <empty-state
      v-if="marketList.length < 1"
      :title="
        $t('alarm.noresultSearch', {
          currency: searchQuery,
        })
      "
      icon="search-not-found"
    />
  </div>
</template>

<script lang="ts" setup>
import type { MarketConfig } from '~/models/main'

const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
})

const mainStore = useMainStore()
const router = useRouter()
const route = useRoute()
const searchQuery = ref<string | number>('')
const showClearConfirmation = ref<boolean>(false)
const historySearches = ref<MarketConfig[]>([])
const exceedCount = ref<boolean>(false)
const currentCurrency = ref<string>('')
const currentMarket = ref<MarketConfig | null>(null)

const marketList = computed<MarketConfig[]>(() => {
  const allMarkets = Object.values(mainStore.getMarketList) as MarketConfig[]
  const query = String(searchQuery.value).toLowerCase()

  const filtered = searchQuery.value
    ? allMarkets.filter((market) => market.searchString?.toLowerCase().includes(query))
    : allMarkets

  const uniqueMarkets = $_.uniqBy(filtered, (market) => market.name)
  return uniqueMarkets.sort((a, b) => (a.fullName ?? '').localeCompare(b.fullName ?? ''))
})

const highlights = computed(() => {
  return $_.orderBy(mainStore.getMarketList, ['volume_total'], ['desc']).slice(0, 4)
})

const loadHistoryFromLocalStorage = () => {
  const savedData = localStorage.getItem('selectMarketToAlarm')
  if (savedData) {
    const markets: MarketConfig[] = JSON.parse(savedData)
    if (markets.length > 0) {
      historySearches.value = markets
    }
  }
}

const saveHistoryToLocalStorage = (markets: MarketConfig[]) => {
  localStorage.setItem('selectMarketToAlarm', JSON.stringify(markets))
}

const clearSearchHistory = () => {
  localStorage.removeItem('selectMarketToAlarm')
  historySearches.value = []
  showClearConfirmation.value = false
}

const toggleOrClearHistory = () => {
  if (!showClearConfirmation.value) {
    showClearConfirmation.value = true
  } else {
    clearSearchHistory()
  }
}

const addNewAlarm = () => {
  router.push({
    query: { ...route.query, step: 'setAlarm', market: currentMarket.value?.id },
  })
}

const selectMarketToAlarm = (market: MarketConfig) => {
  const count = $_.filter(props.list, (item) => {
    const splitMarket = $_.get(item, 'market', '').split('_')
    return splitMarket[0] === market?.name
  }).length

  currentCurrency.value = market.name ?? ''

  if (count > 5) {
    exceedCount.value = true
    return
  }

  exceedCount.value = false

  const savedData = localStorage.getItem('selectMarketToAlarm')
  const markets = savedData ? JSON.parse(savedData) : []

  if ($_.some(markets, { name: market.name })) {
    currentMarket.value = market
    addNewAlarm()
    return
  }

  const marketWithTimestamp = $_.assign({}, market, { timestamp: Date.now() })
  const updatedMarkets = $_.orderBy([...markets, marketWithTimestamp], ['timestamp'], ['asc'])

  if (updatedMarkets.length > 10) {
    $_.pullAt(updatedMarkets, 0)
  }

  saveHistoryToLocalStorage(updatedMarkets)
  historySearches.value = updatedMarkets
  currentMarket.value = market
  addNewAlarm()
}

onMounted(() => {
  loadHistoryFromLocalStorage()
})
</script>
